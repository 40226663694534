const dataKota = () => {
    return [

        {
            title: "Sumatera Utara",
            tanggal: "20 Juni 2024",
            foto: "assets/image/logoKdeks/KDEKS_Sumut_Logo.png",
            skFile: "assets/image/SkKdeks/SK_KDEKS_SUMUT.pdf",
            kode: "sumut"
        },
        {
            title: "Riau",
            tanggal: "20 Juni 2024",
            foto: "assets/image/logoKdeks/KDEKS_Riau_Logo.jpg",
            skFile: "assets/image/SkKdeks/SK_KDEKS_RIAU.pdf",
            kode: "riau"
        },
        {
            title: "Kepulauan Riau",
            tanggal: "20 Juni 2024",
            foto: "assets/image/logoKdeks/KDEKS_KEPRI_Logo.png",
            skFile: "assets/image/SkKdeks/SK_KDEKS_KEPRI.pdf",
            kode: "kepri"

        },
        {
            title: "Sumatera Barat",
            tanggal: "20 Juni 2024",
            foto: "assets/image/logoKdeks/KDEKS_Sumbar_Logo.png",
            skFile: "assets/image/SkKdeks/SK_KDEKS_SUMBAR.pdf",
            kode: "sumbar"
        },
        {
            title: "Jambi",
            tanggal: "20 Juni 2024",
            foto: "assets/image/logoKdeks/KDEKS_Jambi_Logo.jpg",
            skFile: "assets/image/SkKdeks/SK_KDEKS_JAMBI.pdf",
            kode: "jambi"
        },
        {
            title: "Sumatera Selatan",
            tanggal: "20 Juni 2024",
            foto: "assets/image/logoKdeks/KDEKS_Sumatera_Selatan_Logo.png",
            skFile: "assets/image/SkKdeks/SK_KDEKS_SUMSEL.pdf",
            kode: "sumsel"
        },
        {
            title: "Bengkulu",
            tanggal: "20 Juni 2024",
            foto: "assets/image/logoKdeks/KDEKS_Bengkulu_Logo.jpg",
            skFile: "assets/image/SkKdeks/SK_KDEKS_BENGKULU.pdf",
            kode: "bengkulu"
        },
        {
            title: "Lampung",
            tanggal: "20 Juni 2024",
            foto: "assets/image/logoKdeks/KDEKS_Lampung_Logo.jpg",
            skFile: "assets/image/SkKdeks/SK_KDEKS_Lampung.pdf",
            kode: "lampung"
        },
        {
            title: "Bangka Belitung",
            tanggal: "20 Juni 2024",
            foto: "assets/image/logoKdeks/KDEKS_Babel_Logo.jpg",
            skFile: "assets/image/SkKdeks/SK_KDEKS_Lampung.pdf",
            kode: "babel"
        },
        {
            title: "Gorontalo",
            tanggal: "20 Juni 2024",
            foto: "assets/image/logoKdeks/KDEKS_Gorontalo_Logo.png",
            skFile: "assets/image/SkKdeks/SK_KDEKS_Lampung.pdf",
            kode: "gorontalo"
        },
        {
            title: "Jawa Timur",
            tanggal: "20 Juni 2024",
            foto: "assets/image/logoKdeks/KDEKS_Jawa_Timur.png",
            skFile: "assets/image/SkKdeks/SK_KDEKS_Lampung.pdf",
            kode: "jatim"
        },
        {
            title: "Jawa tengah",
            tanggal: "20 Juni 2024",
            foto: "assets/image/logoKdeks/KDEKS_Jawa_Tengah_Logo_1.png",
            skFile: "assets/image/SkKdeks/SK_KDEKS_Lampung.pdf",
            kode: "jateng"
        },
        {
            title: "Kalimantan Timur",
            tanggal: "20 Juni 2024",
            foto: "assets/image/logoKdeks/KDEKS_Kalimantan_Timur_Logo.jpg",
            skFile: "assets/image/SkKdeks/SK_KDEKS_Lampung.pdf",
            kode: "kaltim"
        },
        {
            title: "Kalimantan Selatan",
            tanggal: "20 Juni 2024",
            foto: "assets/image/logoKdeks/KDEKS_Kalimantan_Selatan_Logo.png",
            skFile: "assets/image/SkKdeks/SK_KDEKS_Lampung.pdf",
            kode: "kalsel"
        },
        {
            title: "Kalimantan Utara",
            tanggal: "20 Juni 2024",
            foto: "assets/image/logoKdeks/KDEKS_Kaltara_Logo.png",
            skFile: "assets/image/SkKdeks/SK_KDEKS_Lampung.pdf",
            kode: "kaltra"
        },
        {
            title: "Maluku Utara",
            tanggal: "20 Juni 2024",
            foto: "assets/image/logoKdeks/KDEKS_Maluku_Utara_Logo.jpg",
            skFile: "assets/image/SkKdeks/SK_KDEKS_Lampung.pdf",
            kode: "malut"
        },
        {
            title: "Nusa Tenggara Barat",
            tanggal: "20 Juni 2024",
            foto: "assets/image/logoKdeks/KDEKS_NTB_Logo.jpg",
            skFile: "assets/image/SkKdeks/SK_KDEKS_Lampung.pdf",
            kode: "ntb"
        },
        {
            title: "Sulawesi Selatan",
            tanggal: "20 Juni 2024",
            foto: "assets/image/logoKdeks/KDEKS_Sulawesi_Selatan_Logo.jpg",
            skFile: "assets/image/SkKdeks/SK_KDEKS_Lampung.pdf",
            kode: "sulsel"
        },
        {
            title: "Sulawesi Tengah",
            tanggal: "20 Juni 2024",
            foto: "assets/image/logoKdeks/KDEKS_Sulawesi_Tengah_Logo.jpg",
            skFile: "assets/image/SkKdeks/SK_KDEKS_Lampung.pdf",
            kode: "sulteng"
        },

    ]
};

export default dataKota;
